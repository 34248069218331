/* global $ sspModules google dotdotdot*/

//Polyfill
import "core-js";
import "regenerator-runtime/runtime";

// Styles include
import "bootstrap";
import "./style/style.scss";
import "./style/ssp/map/fa-markers.css";
import "./style/ssp/map/font-awesome.css";
import "./scripts/fancybox/jquery.fancybox.css";
import "./style/ssp/map/ssp-map.scss";

import "../node_modules/leaflet/dist/leaflet.css";
import '../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
import '../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';
import '../node_modules/leaflet.fullscreen/Control.FullScreen.css';
import '../node_modules/leaflet.fullscreen/Control.FullScreen.js';
import cssVars from '../node_modules/css-vars-ponyfill';
//Scripts include
import Sticky from 'stickyfilljs';
// import mainMenu from './scripts/mainMenu'; 
import articleNavCreator from './scripts/articleNavCreator';
//import articleBodyStylist from './scripts/articleBodyStylist';
import clarificationClient from './scripts/clarification-client';
import searchCategories from './scripts/searchcategories';
import helpers from './scripts/helpers';
import shareThis from "./scripts/shareThis.js";
import politicalCalendar from './scripts/politicalCalendar';

document.addEventListener('DOMContentLoaded', function() {
    // Ponyfill for css variables support for ie
    let schoolColors = document.querySelector('[title="school colors"]');

    if (schoolColors != null) {

        cssVars({
            watch: true,
            variables: cssVariables
        });
        let cssVariables = {};
        $.each(schoolColors.css, function(nr, obj) {
            document.documentElement.style.setProperty('--' + obj.prop, obj.value)
            cssVariables[obj.prop] = obj.value;
        })
    }
     const featureLayout = document.querySelector(".feature");
    const parallaxAll = document.querySelectorAll(".parallax");
    const featureVideoAll = document.querySelectorAll(".feature__video");
    const featureImageAndTextAll = document.querySelectorAll(".feature__image-text");
    const wrapHtml = "<div class='feature__col container'><div class='row'><div class=' col-md-7 offset-md-1 article__body'></div></div></div>";
    
    if(featureLayout){
        const featureChildren = featureLayout.children;
        if(featureVideoAll.length){
            featureVideoAll.forEach((featureVideo) => {
                
                $(featureVideo).nextUntil(".feature__image-text, .parallax, .feature__video ").wrapAll(wrapHtml); 
            });
        }    
        if(featureImageAndTextAll.length){
            featureImageAndTextAll.forEach((imageAndText) =>{
                $(imageAndText).nextUntil(".feature__image-text, .parallax, .feature__video ").wrapAll(wrapHtml);
      
            });
        }
        if(parallaxAll.length){
            parallaxAll.forEach((parallax) =>{
                $(parallax).nextUntil(".feature__image-text, .parallax, .feature__video ").wrapAll(wrapHtml);
      
            });
        }
        $(featureChildren).not(".feature__image-text, .parallax, .feature__video , .feature__col").wrapAll(wrapHtml);
    }
    $(window).resize(function() {
        if ($(window).width() < 768) {
            $(".tiptranslate").detach().appendTo(".google-translate--mobile").removeClass("col-12").addClass("mb-4");
        }
        else if ($(window).width() >= 768) {
            $(".tiptranslate").detach().appendTo(".google-translate").addClass("col-12").removeClass("mb-4");
        }

    });
    if ($(window).width() < 768) {
        $(".tiptranslate").detach().appendTo(".google-translate--mobile").removeClass("col-12").addClass("mb-4");
    }
    if ($(".roles").length && $(window).width() > 768) {
        $(".roles").each(function() {
            var tabContent = $(this).find(".tab-content");
            var tabPane = $(this).find(".tab-pane")

            $(tabPane).appendTo(tabContent);
        });

        $(".nav-link").click(function() {
            if ($(this).hasClass('active')) {
                $('#' + this.hash.substr(1).toLowerCase()).toggleClass('active show');
            }
            if (!$(this).hasClass('active')) {
                $(".nav-link").removeClass('icon__button--open');
            }
            $(this).toggleClass('icon__button--open');
        });

    }
    sspModules.map({
        map_container: $("[data-mapinit]"),
        force_fit_pois: true,
        start_location: [59.215984, 9.612507],
        dragging: true,
        create_contact: {
                run: false,
        },
        tap: false,
        wheel_zoom: true,
        
    });


    sspModules.articleNav({
        articleHeader: $(".article-content h2:not(.timeline__section__level):not(.no-section)"),
        navElements: $(".article-content h2:not(.timeline__section__level ), .article-content h3.submenu"),
        affixTopDistance: ".article-content",
        manual_bottom_offset: 1257,
        autoGotoSection: true,
        wrapClass: 'article__body',
        aClass: 'article__nav-anchor border-bottom-0 nav-link',
        liClass: 'article__nav-list-item ',
        articleheaderClass: 'article__header'
    });

    if (!$(".basic-article-menu article h2").length) {
        $(".basic-article-menu .article__nav ").remove();
        $(".basic-article-menu .article-heading").removeClass("offset-md-4").addClass("offset-md-2");
        $(".basic-article-menu article").removeClass("offset-md-1").addClass("offset-md-2");
    }
    const mediaQueryMd = window.matchMedia('(max-width: 768px)');
    
    const topFeatureImage = document.querySelector(".feature__top-image");
    if(topFeatureImage){
        function handleTabletChange(e) {
          // Check if the media query is true
          if (e.matches) {
            // Then log the following message to the console
            topFeatureImage.setAttribute("data-addon_settings", ",h_200");
          }
        }
        
        // Register event listener
        mediaQueryMd.addListener(handleTabletChange);
        
        // Initial check
        handleTabletChange(mediaQueryMd);
    }
    // Customized article nav
    // articleNavCreator({
    //     rootElement: document.querySelector('.article__container'),
    //     navContainer : document.querySelector('.article__nav-list'),
    //     liClass : 'article__nav-list-item',
    //     liAnchorClass : 'article__nav-anchor'
    // });

    // articleBodyStylist({
    //     rootElement: document.querySelector('.article__container'),
    //     headerTag : 'h3',
    //     headerClass : 'article__header article__header--white article__header--partial-with-body',
    //     bodyClass : 'article__body article__body--white article__body--partial-with-header'
    // });

    sspModules.imageCompression({
        // addon_settings : ",f_auto",
    });

    $('article img:not(.leaflet-tile-container > img)').each(function() {
        if (!$(this).hasClass("skip-gallery")) {
            $(this).addClass("img-gallery");
        }
    });
    if ($("article .article__body").length) {
        sspModules.createGallery({
            findImg: ".img-gallery",
            eachContainer: $("article .article__body"),
            minImageCount: 1,
        });

    }
    else {
        sspModules.createGallery({
            findImg: ".img-gallery",
            eachContainer: $("article .article__container")
        });
    }


    // var select = document.getElementById("selectgotolink");
    
    // if(select) {
    //     var active = select.value;
    //     var button = document.getElementById("gotolink_button");
    //     button.addEventListener("click", () => {
    //         console.log(select);
    //         location.href=active;
    //     }, false)
    // }
    

    sspModules.createCollapse({
        nextuntil: "h1,h2,h3,h4,h5,h6,div:not(.profilecard-container):not(.clarification),.stopaccor",
        imageReload: true,
        linkClass: 'article__collapse-link',
        contentClass: 'article__collapse',
        openClass: 'article__collapse--open',
        linkOpenClass: 'article__collapse-link--open'
    });

    clarificationClient();
    console.log("should run clarification v2");

    // Position sticky polyfill
    if (document.querySelector('.article__nav')) {
        Sticky.addOne(document.querySelector('.article__nav'));
    }

    $("table").wrap("<div class='table-responsive'></div>");


    /// hentet fra vfk 
    $(".timeline li").each(function() {

        $(this).wrapInner("<div class='timeline__section'>");
        $(this).find(".timeline__section ul").insertAfter($(this).find(".timeline__section"));

    });

    $(".to-top").click(function(e) {
        e.preventDefault();
        $("body,html").animate({ scrollTop: 0 }, 150);
    });

    function checkOffset() {
        var footerHeight = $("footer").height() + 20;
        if ($('.to-top').offset().top + $('.to-top').height() >= $('footer').offset().top - 10) {
            $('.to-top').addClass('position-absolute').removeClass("position-fixed");

            $(".to-top").css('bottom', footerHeight);
        }
        if ($(document).scrollTop() + window.innerHeight < $('footer').offset().top) {
            $('.to-top').addClass("position-fixed").removeClass("position-absolute"); // restore when you scroll up
            $(".to-top").css("bottom", "");

        }
    }

    $(document).scroll(function() {
        checkOffset();
    });

    shareThis({
        facebookAnchors: document.querySelectorAll('[data-shareThis="facebook"]'),
        linkedinAnchors: document.querySelectorAll('[data-shareThis="linkedin"]'),
        twitterAnchor: document.querySelectorAll('[data-shareThis="twitter"]')
    });

    if ($(".news-box").length) {
        $(".news-box__title-container h3").dotdotdot({
            height: 120
        });
        $(".news-box__description").dotdotdot({
            height: 90
        });
    }


    const targetBlank = document.querySelectorAll("[target='_blank']");
    if (targetBlank) {
        targetBlank.forEach(link => {
            link.setAttribute("rel", "noreferrer  noopener");

        })
    }
    const downloadFiles = document.querySelectorAll(
        "article a[href$='.svg'], .infoblock a[href$='.svg'], article a[href$='.eps'], .infoblock a[href$='.eps'], article a[href$='.ai'], .infoblock a[href$='.ai'], article a[href$='.zip'], .infoblock a[href$='.zip'], article a[href$='.ase'], .infoblock a[href$='.ase'], article a[href$='.png'], .infoblock a[href$='.png'], article a[href$='.jpg'], .infoblock a[href$='.jpg'], article a[href$='.indd'], .infoblock a[href$='.indd'], article a[href$='.ppt'], .infoblock a[href$='.ppt'], article a[href$='.pptx'], .infoblock a[href$='.pptx'], article a[href$='.xlsx'], .infoblock a[href$='.xlsx'], article a[href$='.xls'],.infoblock a[href$='.xls'], article a[href$='.docs'],.infoblock a[href$='.docs'], article a[href$='.docx'],.infoblock a[href$='.docx'], article a[href$='.pdf'], .infoblock a[href$='.pdf']"
    );
    const externalLink = document.querySelectorAll("article a[target='_blank'], .infoblock a[target='_blank']")
    
    
    if (downloadFiles) {
        downloadFiles.forEach(link => {
            console.log("ChildNode", link.children);
            if (link.children.length < 1) {
                let linkText = link.text.trim();
                link.innerHTML = "<span class='material-icons mr-1 align-middle font-size--18' aria-hidden='true'>get_app</span>" + linkText;
            }
        });
    }
    if (externalLink) {
        externalLink.forEach(link => {
            console.log("includes", link.getAttribute("href").includes(".pdf"))
            if (link.children.length < 1 &&
                link.getAttribute("href").includes(".pptx") != true &&
                link.getAttribute("href").includes(".ppt") != true &&
                link.getAttribute("href").includes(".xlsx") != true &&
                link.getAttribute("href").includes(".xls") != true &&
                link.getAttribute("href").includes(".docx") != true &&
                link.getAttribute("href").includes(".docs") != true &&
                
                link.getAttribute("href").includes(".png") != true &&
                link.getAttribute("href").includes(".jpg") != true &&
                link.getAttribute("href").includes(".svg") != true &&
                link.getAttribute("href").includes(".eps") != true &&
                link.getAttribute("href").includes(".ai") != true &&
                link.getAttribute("href").includes(".zip") != true &&
                link.getAttribute("href").includes(".ase") != true &&
                link.getAttribute("href").includes(".indd") != true &&
                
                link.getAttribute("href").includes(".pdf") != true
            ){
                let linkText = link.text.trim();
                link.innerHTML = "<span class='material-icons mr-1 align-middle font-size--18' aria-hidden='true'>launch</span>" + linkText;
            }
        });
    }

    const target = document.querySelector("[data-js=politicalCalendar]");

    if (target) {
        const restUrl = target.getAttribute("data-url");
        politicalCalendar(restUrl, target);
    }


    let onclickButtonClass = ".collapse__button";
    let onclickIconClass = ".collapse__icon";
    let iconClosed = "expand_more";
    let iconOpen = "expand_less";
    
    if (onclickButtonClass) {
        let accordionButtons = document.querySelectorAll(onclickButtonClass);
        accordionButtons.forEach(accordionButton => {
            accordionButton.addEventListener("click", event => {
                let icon = accordionButton.querySelector(onclickIconClass);
                icon.innerText = accordionButton.getAttribute("aria-expanded") == "false" ? iconOpen : iconClosed;
                
            });

        });
    }
    
    
    document.querySelectorAll(".submenu .list-inline").forEach(submenu => {

        if(submenu.children){
            if(submenu.children.length == 0){
                submenu.parentElement.remove()
            }
        }
       
    });
    
    
    /**
     * Table of contents
     * 
     */
    if (document.querySelector('.table-of-contents-container'))
    {
        const tableOfContents = 
            `<div class="toc-container">
                <h2 id="toc-title" style="font-size: 20px; font-weight: 700; margin-bottom: 10px;">
                    På denne siden
                </h2>
                <nav aria-labelledby="toc-title" class="toc-nav">
                    <ul id="toc-nav" class="nav affix-top" data-spy="affix">
                        
                    </ul>
                </nav>
            </div>
            
            <div class="container fixed-toc-container remove" style="background: transparent;">
                <div class="row" style="background: transparent;">
                    <div class="col-sm-7 fixed-toc-col" style="padding-left: 0; padding-right: 30px;">
                        
                        <button
                            class="table-of-contents__toggle"
                            id="fixed-toc-btn"
                            data-toggle="collapse"
                            data-target="#fixed-toc-nav"
                            data-bs-target="#toc-list"
                            aria-label="vis artikkel-innholdsmeny"
                            aria-expanded="false"
                            aria-controls="fixed-toc-nav"
                        >
                            <h2 id="fixed-toc-title" style="font-size: 20px; margin-bottom: 0; font-weight: 700;">
                                På denne siden
                            </h2>
                            <div class="arrow-container">
                                <svg aria-hidden="true" class="arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 30px;">
                                    <title>arrow-down</title>
                                    <path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z" />
                                </svg>
                            </div>
                        </button>
                        
                        <nav aria-labelledby="fixed-toc-title" class="fixed-toc-nav collapse styled-scrollbars" id="fixed-toc-nav">
                            <ul id="fixed-toc-list" class="nav affix-top" data-spy="affix">
                                
                            </ul>
                        </nav>
                        
                    </div>
                </div>
            </div>`;
            
            
        var markup = '';
    	var headingnr = 0;
    	
        $("article h2, article h3, article h4.submenu").each(function(){
        	headingnr++;
        	var tagName = $(this).prop("nodeName").toLowerCase();
        	var idName = "heading-" + tagName + "-" + headingnr;
        	$(this).attr("id", idName);
            
            markup += `<li class="level${tagName}">
        	    <div style="width: 20px; margin-right: 6px; margin-bottom: 4px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                </div>
        	    <a href="#${idName}">
        	        ${$(this).text()}
                </a>
        	 </li>`;
        });
    	
    	if (headingnr > 1) {
            $(".table-of-contents-container").append(tableOfContents);
            
            $("#toc-nav").append(markup);
    	    $("#fixed-toc-nav").append(markup);
    	    
    	    const tocContainer = document.querySelector(".toc-container");
        	const tocContainerNav = document.querySelector('.toc-nav');
        	
        	const fixedTocContainer = document.querySelector('.fixed-toc-container');
        	const fixedTocBtn = document.querySelector("#fixed-toc-btn");
            const fixedTocNav = document.querySelector('.fixed-toc-nav');
            
            fixedTocNav.addEventListener('click', () => {
                fixedTocNav.classList.remove('show');
            });
            
            const arrowDown = `<svg class="arrow-down" style="width: 30px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-down</title><path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z" /></svg>`;
            const arrowUp =   `<svg class="arrow-up" style="width: 30px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-up</title><path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" /></svg>`;
            const arrowContainer = document.querySelector('.arrow-container');
            
            fixedTocContainer.addEventListener('click', () => {
                const svg = arrowContainer.querySelector('svg');
                
                if (svg.classList.contains('arrow-down')) {
                    arrowContainer.innerHTML = arrowUp;
                } else {
                    arrowContainer.innerHTML = arrowDown;
                }
            });
            
            function isInViewport(element) {
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
            
            document.addEventListener("scroll", () => {
                var bounding = tocContainer.getBoundingClientRect();
                //console.log(bounding);
                
                if (
                    (
                        bounding.top >= 0 &&
                        bounding.left >= 0 &&
                        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                    ) ||
                    (window.scrollY >= 0 && window.scrollY <= 100)
                ) {
                    //console.log('inside2');
                    fixedTocContainer.classList.add('remove');
                    fixedTocContainer.classList.remove('show');
                } else {
                    //console.log('outside2');
                    fixedTocContainer.classList.add('show');
                    fixedTocContainer.classList.remove('remove');
                }
            } /*, { passive: true } */);
        }
    }

});
