// The following positions clarificatin popup according to screen width and clarification button position.  
const defaultSettings = {
    clarificatinTextWidth: "330px",
    fullWidthMargins: "15px",
    clarificatinTextCenteringRight: "50%"

}

export default function(args = {}) {
    const settings = {
        ...defaultSettings,
        ...args
    }
    const { clarificatinTextWidth, fullWidthMargins, clarificatinTextCenteringRight } = settings;

    var clarification = document.querySelector(".clarification");

    if (clarification) {
        const clarificationAll = document.querySelectorAll(".clarification");
        
        // var urlRegex =/(\b((https?|ftp|file):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?)/ig;

        
        clarificationAll.forEach(el => {
            
            
            const clarificationTextContainer = el.querySelector(".clarification__text__container");
            
            // clarificationTextContainer.innerHTML = clarificationTextContainer.innerHTML.replace(urlRegex, function(url) {
            //     var newUrl = url.indexOf("http") === -1 ? "http://" + url : url;
            //     return '<a href="' + newUrl + '">' + url + '</a>';
            // });

            
            document.addEventListener('click', function(event) {
              var isClickInside = el.contains(event.target);
              
              if(isClickInside){
                //   console.log("inside click");
                //   el.click();
              }
              else {
                clarificationTextContainer.classList.add("collapse");
                //   console.log("outside click");
              }
            
            //   if (!isClickInside) {
            //     //the click was outside the specifiedElement, do something
            //     console.log("test inside")
            //   }
            //   else {
            //       console.log("outside");
            //   }
            });
            

            var clarificationButton = el.querySelector(".clarification__button");
            if (clarificationButton) {
                clarificationButton.setAttribute("title", "Les begrepsavklaring");
            
            
                el.addEventListener("click", (startbutton) => {
                    el.style.position = "initial";
                    
                    
                    startbutton.stopPropagation();
                    
                    const pageWidth = document.getElementsByTagName("BODY")[0].offsetWidth;
                    let clarificationScreenLeft = el.getBoundingClientRect().left;
                    let clarificationScreenRight = el.getBoundingClientRect().right;
                    clarificationTextContainer.style.left = "initial";
                    clarificationTextContainer.style.right = "initial";
                    clarificationTextContainer.style.width = clarificatinTextWidth;
                    clarificationTextContainer.style.marginRight = "initial";
                    
                    
                    if([...clarificationTextContainer.classList].includes("collapse")){
                        // console.log("removing", clarificationTextContainer.classList);
                        clarificationTextContainer.classList.remove("collapse");
                    }
                    else {
                        // console.log("adding");
                    }
                    
                    // clarificationClose.addEventListener("click", (closebutton) =>{
                    //     // alert("yeay");
                    //     console.log("collapse again");
                    //     // clarificationTextContainer.classList.add("collapse");
                    //     // clarificationTextContainer.classList.add("per");
                    // })
    
                    if (clarificationScreenLeft >= 360) {
                        el.style.position = "relative";
                        clarificationTextContainer.style.right = "0";
                    }
                    else if (pageWidth - clarificationScreenRight >= 360) {
                        el.style.position = "relative";
                        clarificationTextContainer.style.left = "0";
    
                    }
                    else if (pageWidth <= 360) {
                        clarificationTextContainer.style.left = fullWidthMargins;
                        clarificationTextContainer.style.width = "90%";
                        clarificationTextContainer.style.right = fullWidthMargins;
    
                    }
                    else {
                        clarificationTextContainer.style.right = clarificatinTextCenteringRight;
                        clarificationTextContainer.style.marginRight = "-165px" ;
                    }
                    
                    
                    el.querySelector(".clarification--close").focus();
                    
                    // console.log("log");
                    
                    // document.addEventListener('click', function(event) {
                    //   var isClickInside = el.contains(event.target);
                      
                    //   if(isClickInside){
                    //       console.log("outside click");
                    //       el.click();
                    //   }
                    
                    //   if (!isClickInside) {
                    //     //the click was outside the specifiedElement, do something
                    //     console.log("test inside")
                    //   }
                    //   else {
                    //       console.log("outside");
                    //   }
                    // });
                    
                    
                });
            }
            
            const clarificationClose = el.querySelector(".clarification--close");
            // closing
            if(clarificationClose) {
                clarificationClose.addEventListener("click", (closebutton) => {
                    
                    // console.log("close me");
                    closebutton.stopPropagation();
                    clarificationTextContainer.classList.add("collapse");
                    // console.log("closed");
                    
                }, false)
            
            }
            else {
                console.log("not working");
            }
        });

        

    }
};
