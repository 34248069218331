export default function(scriptElm){
    const categoryArr = window.location.search.split("&")
        .map(str => {
            const stripped = str.replace(/\?/gi,"");
            const newStr = stripped.split("=");
            
            if(newStr[0] === "categoryid"){
                return newStr[1];
            }
            
            return null;
        })
        .filter(elm => elm);
    
    if(categoryArr.length > 0){
        console.log(categoryArr[0])
        const anchor = document.querySelector(`[data-categoryid='${categoryArr[0]}']`);
        
        anchor.click();
    }
}