
Handlebars.registerHelper('where', function( collection, key, value, limit, options ){
	options = options || limit;
	if( typeof limit !== 'number' ) limit = Infinity;
	var matches = 0; 
	var result = '';
	for( var i = 0; i < collection.length; i++ ){
		if( collection[i][key] === value ){
			result += options.fn( collection[i] );
			matches++;  
			if( matches === limit ) return result;
		}
	}
	return result;
});

Handlebars.registerHelper('urlifyname', function(name) {
    
    return name.toLowerCase().replace(/\s/gi, "-").replace(/\ø|\Ø|\ö|\Ö/ig, "o").replace(/\Å|\å|\æ|\Æ|\ä|\Ä|\å|\Á|\Å|\å/ig, "a").replace(/\š/ig, "s").replace(/\ž/ig, "z");

});

Handlebars.registerHelper('if_eq', function(a,b, opts) {
    if(a == b) 
        return opts.fn(this);
    else
        return opts.inverse(this);
});

Handlebars.registerHelper('tolowercase', function(a) {
	if(a !== null && typeof a !== "undefined" && a !== "")
    return a.toLowerCase();
});

Handlebars.registerHelper('if_not', function(a, b, opts) {
    if(a !== b) 
        return opts.fn(this);
});