
import fetch from 'isomorphic-fetch';
import dayjs from 'dayjs';


// Utility functions
const numToMonth = (num) => {
    const months = {
        '01': 'jan', '02': 'feb', '03': 'mar', '04': 'apr', '05': 'mai', '06': 'jun',
        '07': 'jul', '08': 'aug', '09': 'sep', '10': 'okt', '11': 'nov', '12': 'des'
    };
    return months[num] || num;
};


const filterParenthesis = (str) => str.replace(/ *\([^)]*\) */g, "");


const fetchJSON = async (url) => {
    const response = await fetch(url, {
        headers: { 'Accept': 'application/json' }
    });
    return response.json();
};



// Settings
const customerID = 'vfk';
const basePathCalendar  = `/no/kalender/#viewtype:item/templatetype:1/daytype:1/`;
const basePathPolitical = `/no/meny/politikk/motekalender-og-sakerNY`;
const SRUpath = `https://sru.vestfoldfylke.no/api`;



const formatCalendarEvents = (calendarEvents) => {
    return calendarEvents.map(event => ({
        Type: 'calendar',
        Description: event.KalenderObjekt.Name,
        Start: event.FraDato,
        End: event.TilDato,
        Id: event.Id,
        KalenderId: event.KalenderId,
        Place: event.KalenderObjekt.Sted[0]?.Name,
        Original: event,
        Nettside: event.KalenderObjekt.Nettside
    }));
};


const formatPoliticalEvents = (events) => {
    return events.map((item) => {
        return {
            Type: 'political',
            Description: item.UtvalgNavn,
            Start: item.Start,
            End: item.Slutt,
            Id: item.Id,
            UtvalgId: item.UtvalgId,
            Place: item.Sted,
            Original: item
        }
    });
}



const getCalendar = (config, cb) => {
    /*let service = "tidspunkt";
    
    if (config.id != "") {
        service = "kalender";
    }
    
    const url = 
        `https://sspkalender.prokom.no/api/${service}` +
        `?Categories=${config.categories}` +
        `&SearchText=${config.search}` +
        `&DateFrom=` +
        `&DateTo=` +
        `&Municipalities=${config.munic}` +
        `&Kunde=${config.client}` +
        `&Id=${config.id}` +
        `&ItemDate=${config.itemdate}` +
        `&WeekDays${config.weekdays}` +
        `&List=${config.list}` +
        `&Count=${config.count}` +
        `&Distributor=${config.distributor}`;

    
    fetch(url).then(data => data.json()).then(data => {
        if (typeof cb === "function") {
            cb(data);
        }
    });
    */
    
    return new Promise((resolve, reject) => {
        let service = "tidspunkt";
        if (config.id != "") {
            service = "kalender";
        }

        const url = 
            `https://sspkalender.prokom.no/api/${service}` +
            `?Categories=${config.categories}` +
            `&SearchText=${config.search}` +
            `&DateFrom=` +
            `&DateTo=` +
            `&Municipalities=${config.munic}` +
            `&Kunde=${config.client}` +
            `&Id=${config.id}` +
            `&ItemDate=${config.itemdate}` +
            `&WeekDays${config.weekdays}` +
            `&List=${config.list}` +
            `&Count=${config.count}` +
            `&Distributor=${config.distributor}`;

        fetch(url)
            .then(data => data.json())
            .then(resolve)
            .catch(reject); // Pass errors to reject
    });
    
    
    
};



const getPoliticalEvents = (config, cb) => {
    /*fetch(`https://sru.vestfoldfylke.no/api/utvalg`, { headers: { 'Accept': 'application/json' } })
    .then(data => data.json())
    .then(data => {
        var allCommittees = data;
        
        const URLs = data.map(utvalg => `https://sru.vestfoldfylke.no/api/utvalg/${utvalg.Id}/moter`);
        const fetchPromises = URLs.map(endpoint => fetch(endpoint).then(response => response.json()));
        
        Promise.all(fetchPromises).then(responses => {
            
            responses.forEach((item, index) => {
                item.forEach(meeting => {
                    meeting.UtvalgNavn = allCommittees[index].Navn;
                    meeting.UtvalgId = allCommittees[index].Id;
                });
            });
            
            
            if (typeof cb === "function") {
                cb(responses);
            }
        });
        
    });*/
    return new Promise((resolve, reject) => {
        fetch(`${SRUpath}/utvalg`, { headers: { 'Accept': 'application/json' } })
            .then(data => data.json())
            .then(data => {
                const allCommittees = data;
                const URLs = data.map(utvalg => `${SRUpath}/utvalg/${utvalg.Id}/moter`);
                const fetchPromises = URLs.map(endpoint => fetch(endpoint).then(response => response.json()));
                
                Promise.all(fetchPromises)
                    .then(responses => {
                        responses.forEach((item, index) => {
                            item.forEach(meeting => {
                                meeting.UtvalgNavn = allCommittees[index].Navn;
                                meeting.UtvalgId = allCommittees[index].Id;
                            });
                        });
                        
                        resolve(responses);
                    })
                    .catch(reject); // Pass errors to reject
            })
            .catch(reject); // Pass errors to reject
    });
}

// chunk data into 3x3
const chunkThreeByThree = (data) => {
    let rows = [];
    
    const chunkSize = 3;
    for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize);
        rows.push(chunk);
    }
    
    return rows;
};

const getRedirectURL = (calendarData, basePathCalendar, basePathPolitical) => {
    if (calendarData.Type && calendarData.Type === 'calendar') {
        return calendarData.Nettside || `${basePathCalendar}params:{%22Categories%22:%220%22,%22SearchText%22:%22%22,%22DateFrom%22:%2204.10.2024%22,%22DateTo%22:%2204.10.2025%22,%22Municipalities%22:%220%22,%22Kunde%22:%22${customerID}%22,%22Id%22:%22${calendarData.KalenderId}%22,%22ItemDate%22:%2207.10.2024%22,%22WeekDays%22:%22%22,%22List%22:%22compact%22,%22Count%22:%2250%22,%22Distributor%22:%22%22}/`;
    }
    if (calendarData.Type && calendarData.Type === 'political') {
        return `${basePathPolitical}#sru-page=mote&id=${calendarData.Id}&utvalgid=${calendarData.UtvalgId}`;
    }
    return '';
};



const politicalCalendar = (url, target) => {
    if (!url.trim()) return;

    target.classList.add("political-calendar");

    const calConfig = {
        client: 'vfk',
        name: 'vfk',
        kunde: 'vfk',
        id: '',
        munic: '0',
        count: '100',
        list: 'compact',
        DateFrom: '',
        DateTo: '',
        search: '',
        categories: '',
        distributor: '',
        calendarurl: '',
        defaultimage: '',
        itemdate: '',
        weekdays: ''
    };

    getCalendar(calConfig)
        .then(dataCalendar => 
            getPoliticalEvents({})
                .then(dataPolitical => {
                    // political
                    const flat = dataPolitical.flat();
                    const enabled = flat.filter(event => event.Offentlig != false);
                    enabled.sort(function(a, b) {
                      // Turn strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(a.Start) - new Date(b.Start);
                    });
                    const nowTime = Date.now();
                    const futureEvents = enabled.filter(event =>  new Date(event.Slutt).getTime() > nowTime);
                    const tenPoliticalEvents = futureEvents.slice(0, 7);
                    const formattedPolitical = formatPoliticalEvents(tenPoliticalEvents);
                    
                    // calendar
                    const fullListOf = (dataCalendar?.FullListOfEvents) ? dataCalendar.FullListOfEvents : [];
                    const formattedCalendar = formatCalendarEvents(fullListOf);
                    
                    
                    let mergedCalendars = [...formattedPolitical, ...formattedCalendar];
                    mergedCalendars.sort(function(a, b) {
                      // Turn strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(a.Start) - new Date(b.Start);
                    });
                    
                    // we only need 9 items (3 rows)
                    let nineOrLessItems = mergedCalendars.slice(0, 9);
                    
                    let rows = chunkThreeByThree(nineOrLessItems);
                    
                    const html = rows.map(row => {
                        const cols = row.map(calendarData => {
                            
                            const redirectURL = getRedirectURL(calendarData, basePathCalendar, basePathPolitical);
                            
                            return `
                                ${
                                    (redirectURL !== '' ? `
                                        <a
                                            href="${redirectURL}"
                                            class="political-calendar__item text-decoration--none"
                                        >
                                            ${createCalendarItemContent(calendarData)}
                                        </a>
                                    ` : `
                                        <div
                                            class="political-calendar__item text-decoration--none"
                                        >
                                            ${createCalendarItemContent(calendarData)}
                                        </div>
                                    `)
                                }
                                
                            `;
                        });
                        
                        return cols.join("");
                    });
                    
                    
                    let collapsedHTML = (nineOrLessItems.length > 3) ? hiddenRowsHTML(html) : '';
                    
                    
                    target.innerHTML = html[0] + collapsedHTML + itemStyles();
                })
                .catch(handleError(target))
        )
        .catch(handleError(target));
};


const handleError = target => error => {
    console.error("Political calendar error:", error);
    target.innerHTML = `<div class="political-calendar__item">
        Feil ved henting av data. Vennligst prøv igjen senere.
    </div>`;
};

/*
const politicalCalendar = function(url, target) {
    
    if(url.trim().length > 0){
        target.classList.add("political-calendar");
        
        
        const calConfig = {
            client: 'vfk',
            name: 'vfk',
            kunde: 'vfk',
            id: '',
            munic: '0',
            count: '100',
            list: 'compact',
            DateFrom: '',
            DateTo: '',
            search: '',
            categories: '',
            distributor: '',
            calendarurl: '',
            defaultimage: '',
            itemdate: '',
            weekdays: ''
        };
        
        getCalendar(calConfig, (dataCalendar) => {
            
            getPoliticalEvents({}, (dataPolitical) => {
                
                
                
            });
            
        }).catch(error => {
            console.error("Political calendar error: ", error);
            
            target.innerHTML = `
                <div class="political-calendar__item">
                    <div class="political-calendar__item-desc">
                        Feil ved henting av data. 
                        <br />
                        Vennligst prøv igjen senere.
                    </div>
                </div>
            `
        });

    }
}*/

function hiddenRowsHTML(html) {
    return `
        <div class="row collapse" style="width: 100%;" id="infoblock__more-info-politisk-kalender">
            <div class="col-12">
                <div class="collapsed-rows" style="gap: 30px;">
                    ${html[1] && html[1].length ? html[1] : ''}
                    ${html[2] && html[2].length ? html[2] : ''}
                </div>
            </div>
        </div>
        
        <div class="col-12 d-flex justify-content-center" style="margin-bottom: -50px;">
            <button
                style="background: #D2A36C; border-color: #D2A36C;"
                data-toggle="collapse"
                data-target="#infoblock__more-info-politisk-kalender"
                aria-expanded="false"
                class="rounded-circle infoblock__button d-flex justify-content-center align-items-center collapsed"
                aria-controls="infoblock__more-info-568" aria-label="toggle more information Historisk arkiv"
            >
                <span class="material-icons align-middle infoblock__button-icon" aria-hidden="true">keyboard_arrow_down</span>
                <span class="material-icons align-text-top infoblock__button-icon--open" aria-hidden="true">keyboard_arrow_up</span>
            </button>
        </div>
    `;
}

function createCalendarItemContent(calendarData) {
    return `
        <div
            class="political-calendar__item-date"
            style="${(calendarData.Type && calendarData.Type === 'calendar') ? 'background: #fff;' : 'background: #E4C9A8; border: 2px solid #E4C9A8;'}"
        >
            <div class="political-calendar__item-icon">
                ${(calendarData.Type && calendarData.Type === 'calendar') ?
                    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 12H12V17H17V12ZM16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z" fill="black"/>
                    </svg>`
                    :
                    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 13V11H22V13H18ZM19.2 20L16 17.6L17.2 16L20.4 18.4L19.2 20ZM17.2 8L16 6.4L19.2 4L20.4 5.6L17.2 8ZM5 19V15H4C3.45 15 2.97917 14.8042 2.5875 14.4125C2.19583 14.0208 2 13.55 2 13V11C2 10.45 2.19583 9.97917 2.5875 9.5875C2.97917 9.19583 3.45 9 4 9H8L13 6V18L8 15H7V19H5ZM14 15.35V8.65C14.45 9.05 14.8125 9.5375 15.0875 10.1125C15.3625 10.6875 15.5 11.3167 15.5 12C15.5 12.6833 15.3625 13.3125 15.0875 13.8875C14.8125 14.4625 14.45 14.95 14 15.35ZM4 11V13H8.55L11 14.45V9.55L8.55 11H4Z" fill="black"/>
                    </svg>`}
            </div>
            
            <div class="political-calendar__item-day-and-month">
                <div class="political-calendar__item-day">
                    ${dayjs(calendarData.Start).format('DD')}.
                </div>
                
                <div class="political-calendar__item-month">
                    ${numToMonth(dayjs(calendarData.Start).format('MM'))}
                </div>
            </div>
        </div>
        
        <div class="political-calendar__item-desc">
            <div class="political-calendar__desc-title">
                ${filterParenthesis(calendarData.Description)}
            </div>
            
            <div class="political-calendar__desc-time" style="margin-bottom: 6px; margin-top: 6px;">
                <span class="material-icons align-text-top" aria-hidden="true">access_time</span>
                <span class="political-calendar__desc-hour-minute">
                    ${dayjs(calendarData.Start).format('HH:mm')}
                </span>
            </div>
            
            ${
                (calendarData.Type && calendarData.Place && calendarData.Type === 'calendar') ? `
                    <div class="political-calendar__desc-time">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>
                        <span class="political-calendar__desc-hour-minute">
                            ${calendarData.Place}
                        </span>
                    </div>`
                :
                ``
            }
        </div>
    `;
}

function itemStyles() {
    return `<style>
        .collapsed-rows {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
        
        .political-calendar__item {
            flex-basis: calc(100% / 3 - 20px / 3 * (3 - 1));
            flex-grow: 1;
        }
        
        .toppsak-list-item-anchor {
            display: block;
            height: 100%;
        }
        
        @media (max-width: 1000px) {
          .political-calendar__item {
            flex-basis: calc(100% / 2 - 20px / 2 * (2 - 1));
            flex-grow: 1;
          }
        }
        
        @media (max-width: 771px) {
          .political-calendar__item {
            flex-basis: calc(100% / 1 - 20px / 1 * (1 - 1));
            flex-grow: 1;
          }
        }
        
        a.political-calendar__item .political-calendar__desc-title {
            text-decoration: underline;
            margin-bottom: 4px;
        }
        
        a.political-calendar__item:hover .political-calendar__desc-title {
            text-decoration: none;
        }
        
        div.political-calendar__item .political-calendar__desc-title {
            text-decoration: none;
            pointer-events: none;
        }
    </style>`;
}

export default politicalCalendar;