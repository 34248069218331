import filterNewsByCategory from './filterNewsByCategory';

$(document).ready(function() {
    $('.newslist > .category').on('click', '.cat > a', function(e) {
        console.log("Click category: " + this.text);

        e.stopPropagation();
        e.preventDefault();

        var self = $(this);
        var catId = self.attr("data-categoryid");

        $("ul.all-news > li").each(function() {

            // An item can contain multiple cateogires
            let idList = $(this).children("span").attr("data-categoriesid").split(",");
            
            let idFound = false;
            
            // Show item if any of its category IDs are the same as clicked category
            idList.forEach(id => {
                if (id === catId)
                    idFound = true;
            });
            
            idFound ? $(this).show("fast", "linear") : $(this).hide("fast", "linear")
        });
    });

    filterNewsByCategory();
});
