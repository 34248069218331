const addUrl = (elm, url, urlAttr) => {
    
    const urlKeyProps = Object.keys(urlAttr);
    
    const urlProps = urlKeyProps.reduce((result, propKey, index) => {
        if(index > 0){
            result += "&";
        }
        
        result += `${propKey}=${urlAttr[propKey]}`;
        
        return result;
    },"");
    
    
    elm.href = `${url}?${urlProps}`;
    
    return elm;
};

const defaultSettings = {
    facebookAnchor : null,
    linkedinAnchor : null,
    twitterAnchor  : null
};

export default function(args={}){
    
    const settings = {
        ...defaultSettings,
        ...args
    };
    
    const { facebookAnchors, linkedinAnchors, twitterAnchor } = settings;
    
    const facebookUrl = 'https://www.facebook.com/sharer/sharer.php';
    const linkedInUrl = 'https://www.linkedin.com/shareArticle';
    const twitterUrl = 'https://twitter.com/intent/tweet'

    if(facebookAnchors){
        Array.from(facebookAnchors).forEach((facebookAnchor)=>{
            addUrl(
                facebookAnchor,
                facebookUrl, {
                    u : location.href,
                },
            );
        });
    }
    
    if(linkedinAnchors){
        Array.from(linkedinAnchors).forEach((linkedinAnchor)=>{
            addUrl(
                linkedinAnchor,
                linkedInUrl, {
                    mini : true,
                    url : location.href,
                    title : document.title,
                    summary:'',
                    source:''
                },
            );
        });
    }
    if(twitterAnchor){
        Array.from(twitterAnchor).forEach((twitterAnchor)=>{
            addUrl(
                twitterAnchor,
                twitterUrl, {
                    url : location.href,
                },
            );
        });
    }
};
